import {
  BarChartOutlined,
  ContactsOutlined,
  DashboardOutlined,
  DesktopOutlined,
  SettingOutlined,
  ShopOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { getUserData } from "../utils/utils";
import { getReq } from "../utils/constant";
const rootSubmenuKeys = ["sub1", "sub2", "sub3", "sub4", "sub5"];
const merchantSidebar = [
  {
    key: "1",
    icon: <DashboardOutlined />,
    label: <NavLink to="/merchant-dashboard">Dashboard</NavLink>,
  },
  {
    key: "sub1",
    icon: <ShopOutlined />,
    label: "Store Management",
    children: [
      {
        key: "2",
        label: (
          <NavLink to="/storemanagement/registeredPos-list">
            Registered POS
          </NavLink>
        ),
      },
      {
        key: "3",
        label: (
          <NavLink to="/storemanagement/pos-customize">
            Customize POS Screen
          </NavLink>
        ),
      },

      {
        key: "4",
        label: (
          <NavLink to="/storemanagement/add-department">Departments</NavLink>
        ),
      },
      {
        key: "5",
        label: <NavLink to="/storemanagement/add-product">Products</NavLink>,
      },
      {
        key: "6",
        label: <NavLink to="/storemanagement/add-flag">Flags</NavLink>,
      },
      // {
      //   key: "7",
      //   label: (
      //     <NavLink to="/storemanagement/add-promotion">Promotions</NavLink>
      //   ),
      // },
      {
        key: "8",
        label: (
          <NavLink to="/storemanagement/mass-product-update">
            Mass Update
          </NavLink>
        ),
      },
      {
        key: "9",
        label: (
          <NavLink to="/storemanagement/purchase-order">Purchase Order</NavLink>
        ),
      },
      {
        key: "10",
        label: <NavLink to="/storemanagement/store-setting">Settings</NavLink>,
      },
    ],
  },
  {
    key: "sub2",
    icon: <UsergroupAddOutlined />,
    label: "Employee Management",
    children: [
      {
        key: "11",
        label: (
          <NavLink to="/employeeManagement/add-employee-role">Role</NavLink>
        ),
      },
      {
        key: "12",
        label: (
          <NavLink to="/employeeManagement/add-employee">Employee</NavLink>
        ),
      },
      {
        key: "13",
        label: (
          <NavLink to="/report/productivity">Employee Productivity</NavLink>
        ),
      },
    ],
  },
  {
    key: "sub3",
    icon: <BarChartOutlined />,
    label: "Reports",
    children: [
      {
        key: "14",
        label: <NavLink to="/report/sales-report">Sales Report</NavLink>,
      },
      {
        key: "15",
        label: <NavLink to="/report/shift-report">Shift Report</NavLink>,
      },
      {
        key: "16",
        label: <NavLink to="/report/department">Department Report</NavLink>,
      },
      {
        key: "17",
        label: <NavLink to="/report/product">Product Report</NavLink>,
      },
      {
        key: "18",
        label: <NavLink to="/report/tax-report">Tax Report</NavLink>,
      },
      {
        key: "19",
        label: <NavLink to="/report/pay-roll">Pay Roll Report</NavLink>,
      },
      {
        key: "20",
        label: <NavLink to="/report/clock-in-out">Clock In/Out Report</NavLink>,
      },
      {
        key: "21",
        label: <NavLink to="/report/transaction">Transaction Report</NavLink>,
      },
      {
        key: "22",
        label: <NavLink to="/report/batch-report">Batch Report</NavLink>,
      },
      {
        key: "23",
        label: <NavLink to="/report/cona-report">CONA Report</NavLink>,
        showIf: (vendorNumber) => !!vendorNumber,
      },
    ],
  },
  {
    key: "24",
    icon: <UserOutlined />,
    label: <NavLink to="/customer-engagement">Customer Engagement</NavLink>,
  },

  {
    key: "25",
    icon: <ShopOutlined />,
    label: <NavLink to="/store-list">Store List</NavLink>,
  },
  {
    key: "26",
    icon: <ContactsOutlined />,
    label: <NavLink to="/contact-us">Contact Us</NavLink>,
  },
];
//Super Admin Sidebar menu list
const superAdminSidebar = [
  {
    key: "28",
    icon: <DashboardOutlined />,
    label: <NavLink to="/dashboard">Dashboard</NavLink>,
  },
  {
    key: "29",
    icon: <UserOutlined />,
    label: <NavLink to="/manage-agent">Agents</NavLink>,
  },
  {
    key: "30",
    icon: <UserAddOutlined />,
    NavLink: "/manage-merchant",
    label: <NavLink to="/manage-merchant">Merchants</NavLink>,
  },
  {
    key: "31",
    icon: <ShopOutlined />,
    NavLink: "/storemanagement/add-store",
    label: <NavLink to="/storemanagement/add-store">Stores</NavLink>,
  },
  {
    key: "32",
    icon: <DesktopOutlined />,
    NavLink: "/storemanagement/add-pos",
    label: <NavLink to="/storemanagement/add-pos">POS</NavLink>,
  },
  {
    key: "33",
    icon: <SettingOutlined />,
    NavLink: "/user-management",
    label: <NavLink to="/user-management">User Management</NavLink>,
  },
];
const subAdminSidebar = [
  {
    key: "28",
    icon: <DashboardOutlined />,
    label: <NavLink to="/dashboard">Dashboard</NavLink>,
  },
  {
    key: "29",
    icon: <UserOutlined />,
    label: <NavLink to="/manage-agent">Agents</NavLink>,
    showIf: "isAllowViewAgent",
  },
  {
    key: "30",
    icon: <UserAddOutlined />,
    NavLink: "/manage-merchant",
    label: <NavLink to="/manage-merchant">Merchants</NavLink>,
    showIf: "isAllowViewMerchant",
  },
  {
    key: "31",
    icon: <ShopOutlined />,
    NavLink: "/storemanagement/add-store",
    label: <NavLink to="/storemanagement/add-store">Stores</NavLink>,
    showIf: "isAllowViewStore",
  },
  {
    key: "32",
    icon: <DesktopOutlined />,
    NavLink: "/storemanagement/add-pos",
    label: <NavLink to="/storemanagement/add-pos">POS</NavLink>,
    showIf: "isAllowViewPOS",
  },
];
const agentSidebar = [
  {
    key: "40",
    icon: <DashboardOutlined />,
    label: <NavLink to="/agent-dashboard">Dashboard</NavLink>,
  },
  {
    key: "41",
    icon: <UserOutlined />,
    label: <NavLink to="/manage-merchant">Merchants</NavLink>,
  },
  {
    key: "42",
    icon: <ShopOutlined />,
    label: <NavLink to="/storemanagement/add-store">Stores</NavLink>,
  },
  {
    key: "43",
    icon: <DesktopOutlined />,
    label: <NavLink to="/storemanagement/add-pos">POS</NavLink>,
  },
];
const RoleWiseSidebar = ({ role }) => {
  const [openKeys, setOpenKeys] = useState([]);
  const [sidebar, setSidebar] = useState([]);
  const [vendorNumber, setVendorNumber] = useState(null);
  const [userPermissions, setUserPermissions] = useState({});
  const navigate = useNavigate();
  useEffect(
    (role) => {
      const localData = JSON.parse(getUserData());
      setVendorNumber(localData?.vendorNumber || null);
      setUserPermissions(localData?.permissions || {});
      const item = localStorage.getItem("openKeys");
      if (item) {
        setOpenKeys([item]);
      }
      if (role === "admin") {
        getAdminPermissions();
      }
    },
    // eslint-disable-next-line
    [role]
  );
  useEffect(() => {
    switch (role) {
      case "super-admin":
        setSidebar(getSidebar("super-admin"));
        break;
      case "admin":
        setSidebar(getSidebar("admin"));
        break;
      case "agent":
        setSidebar(getSidebar("agent"));
        break;
      case "merchant":
        setSidebar(getSidebar("merchant", vendorNumber));
        break;
      case "super-merchant":
        setSidebar(getSidebar("merchant", vendorNumber));
        break;
      default:
        return console.warn("Role not found");
    }
    // eslint-disable-next-line
  }, [role, vendorNumber, userPermissions]);

  const getAdminPermissions = async () => {
    const localData = JSON.parse(getUserData());
    const { _id } = localData;
    const response = await getReq(
      `web/adminPermissions?adminId=${_id}`,
      navigate
    );
    const { result } = response.data;
    setUserPermissions(result[0]?.permissions || {});
  };
  const onOpenChange = (keys) => {
    localStorage.setItem("openKeys", keys[keys.length - 1]);
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  const getSidebar = (userRole, vendorNumber) => {
    switch (userRole) {
      case "super-admin":
        return superAdminSidebar;
      case "admin":
        return subAdminSidebar.map((item) => {
          if (item.showIf) {
            // Check if the user has the necessary permission to view this item
            const hasPermission = userPermissions[item.showIf];
            if (!hasPermission) {
              return null; // or return an empty object to hide the item
            }
          }
          return item;
        });
      case "agent":
        return agentSidebar;
      case "merchant":
        return merchantSidebar.map((item) => {
          if (item.children) {
            return {
              ...item,
              children: item.children.filter((child) => {
                // If the child has a showIf function, call it with vendorNumber
                if (child.showIf) {
                  return child.showIf(vendorNumber);
                }
                // Otherwise, include the child
                return true;
              }),
            };
          }
          return item;
        });
      default:
        return [];
    }
  };
  return (
    <Menu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={window.location.pathname}
      defaultOpenKeys={openKeys}
      items={sidebar}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
    />
  );
};
export default RoleWiseSidebar;
